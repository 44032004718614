
import { useAuth0 } from "@auth0/auth0-react";
import API from "@sesame/web-api";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { ClientLogo } from "src/components";
import ModalDialog from "src/components/ModalDialog";
import { Spinner } from "src/components/Spinner";
import Alert, { AlertTypes } from "src/components/AlertDisplay"
import { ILoadState, INewTestEvent, ITransceiver, ValidEventNames } from "src/types";
import { Navigate } from "react-router-dom";
import { workflowStates } from "./common";
import { beep } from "src/util/PlaySound";


export const FWCheckin= () => {

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW)
  const [boxCode, setBoxCode] = useState("");
  const [partNo, setPartNo] = useState("");
  const [txCode, setTxCode] = useState("");
  const [scannedTx, setScannedTx] = useState<string[]>([])

  const configVals = {
    title: 'Scan Received Transceivers',
    testName: workflowStates[0] as ValidEventNames,
  }

  const partChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPartNo(e.currentTarget.value);
  }

  const boxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setBoxCode(e.currentTarget.value);
    setTxCode("");
    setScannedTx([]);
  }

  const txScanned = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setTxCode(e.currentTarget.value);
  }


  const addScan = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!txCode.length) {
      document.getElementById('serialInput')?.focus();
      return

    }
    const newScan = txCode;
    const parts = newScan.split(" ");
    if (parts.length == 0) {
      // not sure what's happening here, but well use what we have.
      return;
    }
    var partNo = ""
    if (parts.length > 1) {
      setPartNo(parts[1]);
      partNo = parts[1];
    }
    sendScannedCode(parts[0], boxCode, partNo)
  }

  const sendScannedCode = (serialNo: string, boxCode: string, partNo: string) => {
    getAccessTokenSilently().then((accessToken) => {
      const d = new Date()
      const te:  INewTestEvent =
       {
        date: d.toJSON(),
        location: boxCode,
        name: `${configVals.testName}`,
        result: "P",
        serialNo,
        partNo: partNo,
        revision: "",
        vendor: "",
        output: "",
      }
      API.post(accessToken, "/api/v1/testEvent",te)
        .then(res => {
          const ix = scannedTx.findIndex(s => s == serialNo);
          if (ix >= 0 ) {
            scannedTx.splice(ix,1)
          }

          const scanList = [serialNo, ...scannedTx]
          setScannedTx(scanList);
          setTxCode("")
          document.getElementById('serialInput')?.focus();
          beep(50, 'sine', 610);
        })
        .catch(err => {
          Alert(AlertTypes.ERROR, err.message);
          return;
        })
    })
      .catch(err => {
        Alert(AlertTypes.ERROR, err.message);
      })
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }
  return <div className="txfwpage">
    <h2>{configVals.title}</h2>
    <form>
      <label className="uk-label highlight-receiving">Part No</label>
      <br />
      <input className="uk-input uk-width-1-2"
        type="text"
        placeholder="QSFP-LR4-DD-400G"
        value={partNo}
        autoFocus={partNo == ""}
        onChange={e => partChanged(e)}
      />
      <br />
      <br />
      <label className="uk-label highlight-receiving">Box Code</label>
      <br />
      <input className="uk-input uk-width-1-2"
        type="text"
        placeholder="Scan box code"
        value={boxCode}
        autoFocus={boxCode == ""}
        onChange={e => boxChanged(e)}
      />
      <br />
      <br />
      <label className="uk-label highlight-receiving">Transceiver Serial Number</label>
      <br />
      <input className="uk-input uk-width-1-2"
        id='serialInput'
        type="text"
        placeholder="Scan transceiver barcode"
        value={txCode}
        autoFocus={boxCode != ""}
        disabled={boxCode.length < 2}
        onChange={e => txScanned(e)}
      />
      <br />
      <br />
      <button className="uk-button uk-button-primary highlight-receiving"
        onClick={e => addScan(e)}>Record</button>
    </form>
    <h2>Box Count: {scannedTx.length}</h2>
    <ul className="uk-list">
      {scannedTx.map(t => <li>{t}</li>)}
    </ul>
  </div>
}
