
export interface INewTestEvent {
  date: string,   //API needs a Date.toJSON() op for this.
  location: string,
  name: ValidEventNames,
  result: string,
  serialNo: string,
  partNo: string,
  revision: string,
  vendor: string,
  output: string,
}

export interface ITestEvent {
  eventID: number,
  userIdentity: string,
  testId: string,
  date: Date,
  location: string,
  name: ValidEventNames,
  result: string,
  serialNo: string,
  partNo: string,
  revision: string,
  vendor: string,
  output: string,
}

export type ValidEventNames = 'fwu:checkin'|'fwu:upgradeversioncheck'|'fwu:upgradeqascan'|'fwu:packout'|'inv:cartonpack'|'fwu:error';

export const UpgradeEvents = {
  "fwu:checkin": {display: "Receiving Scan", order: 1},
  "fwu:upgradefw": {display: "Firmware Upgrade", order: 2},
  "fwu:upgradeversioncheck": {display: "FW Version Check", order: 3},
  "fwu:upgradeqascan": {display: "FW Version QA Scan", order: 4},
  "fwu:packout": {display: "Packout Scan", order: 5},
  "inv:cartonpack": {display: "Carton Pack", order: 6},
  "fwu:error": {display: "Error", order: 7},
}