import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import clientLogo from '../assets/images/proficium-logo.png';

// import SVG from 'react-inlinesvg';


export const ClientLogo = (props: React.HTMLAttributes<HTMLImageElement>) =>  {
  const navigate = useNavigate();
  return <a onClick={() => navigate('/')}><img src={clientLogo} alt="Sesame Logo" width="180px"
    className="uk-margin-left uk-margin-botton" /></a>
}

