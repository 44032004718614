
import { useAuth0 } from "@auth0/auth0-react";
import API from "@sesame/web-api";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Spinner } from "src/components/Spinner";
import Alert, { AlertTypes } from "src/components/AlertDisplay"
import { ILoadState, ITestEvent, UpgradeEvents, ValidEventNames } from "src/types";
import { Navigate } from "react-router-dom";
import dayjs from "dayjs";

interface IRepDataPoint {
  name: ValidEventNames,
  part_no: string,
  vendor: string,
  result: string,
  total: number
}
interface IRepTablePoint {
  name: ValidEventNames,
  display: string,
  part_no: string,
  vendor: string,
  pass: number,
  fail: number,
}

export const FWUpgradeSummary = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<IRepTablePoint[]>([]);
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW)

  const processData = (result: IRepDataPoint[]): IRepTablePoint[] => {
    const output: IRepTablePoint[] = [];
    // Sort in order, then add pass/fail total for each unique item

    const sorted = result.filter(e => e.name in UpgradeEvents).sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      if (a.vendor < b.vendor) return -1;
      if (a.vendor > b.vendor) return 1;
      if (a.part_no < b.part_no) return -1;
      if (a.part_no > b.part_no) return 1;
      return 0
    })
    for (let i = 0; i < sorted.length; i++) {
      const s = sorted[i];
      if (i == 0 || !(s.name == sorted[i - 1].name && s.part_no == sorted[i - 1].part_no && s.vendor == sorted[i - 1].vendor)) {

          output.push({
            name: s.name,
            display: UpgradeEvents[s.name].display,
            part_no: s.part_no,
            vendor: s.vendor,
            pass: 0,
            fail: 0
          })
      }
      s.result == 'P' && (output[output.length - 1].pass += s.total);
      s.result == 'F' && (output[output.length - 1].fail += s.total);
    }

    const sortedOutput = output.sort((a, b) => {
      const ao = UpgradeEvents[a.name].order
      const bo = UpgradeEvents[b.name].order
      return ao < bo ? -1 : 1
    })
    return sortedOutput;

  }

  useEffect(() => {
    getAccessTokenSilently().then(async accessToken => {
      const res = await API.get(accessToken, '/api/v1/report/testEvents/upgradeSummary')
        .catch(err => {
          Alert(AlertTypes.ERROR, err.message);
          setDisplayState(ILoadState.ERROR);
          return;
        })
      const sorted = processData(res.data)
      setData(sorted)
      setDisplayState(ILoadState.READY);
    })
    setDisplayState(ILoadState.LOADING);
  }, [])

  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }
  if (isLoading || displayState == ILoadState.LOADING || displayState == ILoadState.NEW) {
    return <div><h2>Updgrade Summary</h2>
      <div><Spinner /></div>
    </div>
  }

  if (displayState == ILoadState.ERROR) {
    return <div><h2>Updgrade Summary</h2>
      <p className="uk-text-danger">Error loading data</p>
    </div>
  }

  return <div>
    <h2>Updgrade Summary</h2>
    <table className="uk-table uk-table-small">
      <tr>
        <th>
          Stage
        </th>
        <th>
          Vendor
        </th>
        <th>
          Part
        </th>
        <th>
          Pass
        </th>
        <th>
          Fail
        </th>
        <th>
          Total
        </th>
      </tr>

      {data.map((d, i) => <tr key={i}>
        <td>
          {d.display}
        </td>
        <td>
          {d.vendor}
        </td>
        <td>
          {d.part_no}
        </td>
        <td>
          {d.pass}
        </td>
        <td>
          {d.fail}
        </td>
        <td>
          {d.pass + d.fail}
        </td>
      </tr>
      )}
    </table>
  </div>
}
