
import { useAuth0 } from "@auth0/auth0-react";
import API from "@sesame/web-api";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { ClientLogo } from "src/components";
import ModalDialog from "src/components/ModalDialog";
import { Spinner } from "src/components/Spinner";
import Alert, {AlertTypes} from "src/components/AlertDisplay"
import { ILoadState, ITransceiver } from "src/types";
import { Navigate } from "react-router-dom";

export const TranscieversPage = () => {
  const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();
  const [transceivers, setTransceivers] = useState<ITransceiver[]>([]);
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW)

  useEffect( () => {
    getAccessTokenSilently().then((accessToken) => {
      API.get(accessToken, "/api/v1/transceivers") 
      .then( res => {
        const data = res.data as ITransceiver[];
        setTransceivers(data)
        setDisplayState(ILoadState.READY)
      })
    })
    .catch( err => {
      Alert(AlertTypes.ERROR, err.message);
    })
  }, [getAccessTokenSilently])

  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }
  if (isLoading || displayState == ILoadState.NEW || displayState == ILoadState.LOADING) {
    return <div><h2>Transceivers</h2>
      <div><Spinner /></div>
    </div> 
  }

  return <div>
    <h2>Transceivers</h2>
    <table className="uk-table uk-table-small">
      <tr>
        <th>
        </th>
        <th>
          Vendor
        </th>
        <th>
          Part
        </th>
        <th>
          Type
        </th>
        <th>
          Power
        </th>
        <th>
          Approved Switches
        </th>
        <th>
          Approved NICs
        </th>
      </tr>


    {transceivers.map( (t,i) => (
      <tr>
        <td className="uk-preserve-width">
          <XcvrReport xcvr={t} id={i}/>
        </td>
        <td>
          {t.vendorName}
        </td>
        <td>
          {t.partNo} : {t.revision}
        </td>
        <td>
          {t.formFactor} {t.xcvrType} {t.cableInterface} {t.speed} {t.distance > 999 ? `${t.distance / 1000}km` : `${t.distance}m`} 
        </td>
        <td>
          {t.maxPower}
        </td>
        <td>
          {t.switchCompatList.length}
        </td>
        <td>
          {t.nicCompatList.length}
        </td>
          
      </tr>
    ))
    }
    </table>
    </div>
}


interface IXcvrReportProps {
  xcvr: ITransceiver;
  id: number;
}

const XcvrReport = ({xcvr, id}: IXcvrReportProps) => {
  return <ModalDialog
     id={`xcrReport-${id}`}
     buttonText={""}
     icon="report"
     title="" 
     >
    <div className="watermark">
      <h2><ClientLogo /></h2>
      <h2 className="uk-text-center">Transceiver Compliance Report</h2>
      {/*<h2>{`${xcvr.vendorName} ${xcvr.partNo} ${xcvr.revision}`}</h2>*/ }
      <table>
        <tr>
          <td>Item:</td>
          <td>{xcvr.partNo} {xcvr.revision}</td>
        </tr>
        <tr>
          <td>Vendor:</td>
          <td>{xcvr.vendorName}</td>
        </tr>
        <tr>
          <td>Form Factor:</td>
          <td>{xcvr.formFactor}</td>
        </tr>
        <tr>
          <td>Type:</td>
          <td>{xcvr.xcvrType}</td>
        </tr>
        <tr>
          <td>Connnector:</td>
          <td>{xcvr.cableInterface}</td>
        </tr>
        <tr>
          <td>Wavelength:</td>
          <td>{xcvr.wavelength} nm </td>
        </tr>
        <tr>
          <td>Cable:</td>
          <td>{xcvr.cableType}</td>
        </tr>
        <tr>
          <td>Data Transfer Rate:</td>
          <td>{xcvr.speed} Gbps</td>
        </tr>
        <tr>
          <td>Maximum Distance:</td>
          <td>{xcvr.distance > 999 ? `${xcvr.distance / 1000} km`: `${xcvr.distance} m`}</td>
        </tr>
        <tr>
          <td>Power Requirement:</td>
          <td>{xcvr.maxPower} mW </td>
        </tr>
      </table>
      <h3>Switch Compatibiliy</h3>
      <table className="uk-table uk-table-small">
        <tr>
          <th>Model</th>
          <th>Result</th>
        </tr>
        {xcvr.switchCompatList.map(s => (
          <tr>
            <td className="uk-width-1-3">{s.model}</td>
            <td>{s.pass ? <span className="uk-text-success reportSuccess" data-uk-icon="icon: check; ratio: 1.25" /> :
              <span className="uk-text-danger" data-uk-icon="close" />
           }
            </td>
          </tr>
        ))}
      </table>
      <h3>NIC Compatibiliy</h3>
      <table className="uk-table uk-table-small">
        <tr>
          <th>Model</th>
          <th>Result</th>
        </tr>
        {xcvr.nicCompatList.map(s => (
          <tr>
            <td className="uk-width-1-3">{s.model}</td>
            <td>{s.pass ? <span className="uk-text-success reportSuccess" data-uk-icon="icon: check; ratio: 1.25" /> :
              <span className="uk-text-danger" data-uk-icon="close" />
            }
            </td>
          </tr>
        ))}
      </table>
      <p>SAMPLE</p>
    </div>
  </ModalDialog>
}