
// https://stackoverflow.com/questions/879152/how-do-i-make-javascript-beep

export const beep = (function() {
  var ctxClass = window.audioContext || window.AudioContext || window.AudioContext || window.webkitAudioContext
  var ctx = new ctxClass();
  return function(duration, type, frequency, finishedCallback) {

    duration = +duration;
    frequency = +frequency || 440;

    // Only 0-4 are valid types.
    type = (type % 5) || 0;

    if (typeof finishedCallback != "function") {
      finishedCallback = function() { };
    }

    var osc = ctx.createOscillator();

    osc.type = type;

    //osc.type = "sine";

    osc.connect(ctx.destination);
    osc.frequency.value = frequency;
    if (osc.noteOn) osc.noteOn(0); // old browsers
    if (osc.start) osc.start(); // new browsers

    setTimeout(function() {
      if (osc.noteOff) osc.noteOff(0); // old browsers
      if (osc.stop) osc.stop(); // new browsers
      finishedCallback();
    }, duration);

  };
})();
