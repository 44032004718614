
import { useAuth0 } from "@auth0/auth0-react";
import API from "@sesame/web-api";
import React, { useState, useEffect, useRef, useMemo } from "react";
import ModalDialog from "src/components/ModalDialog";
import { Spinner } from "src/components/Spinner";
import Alert, { AlertTypes } from "src/components/AlertDisplay"
import { ILoadState, INewTestEvent, ITransceiver, ValidEventNames } from "src/types";
import { Navigate } from "react-router-dom";
import { getTransceiver, sendScannedEvent, workflowStates } from "./common";
import { beep } from "src/util/PlaySound";


export const FWCartonPack = () => {

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW)
  const [cartonSerial, setCartonSerial] = useState("");
  const [packageSerial, setPackageSerial] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [scannedSerials, setScannedSerials] = useState<string[]>([])

  const configVals = {
    title: 'Add Packages to Carton',
    testName: workflowStates[5] as ValidEventNames,
  }


  const cartonChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setCartonSerial(e.currentTarget.value);
    setPackageSerial("");
    setScannedSerials([]);
  }

  const packageSerialScanned = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPackageSerial(e.currentTarget.value);
  }

  const addPackage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const sendPackage = async () => {
      const accessToken = await getAccessTokenSilently();

      document.getElementById('serialInput')?.focus();
      if (!packageSerial.length || !cartonSerial) {
        return;
      }

      const d = new Date()
      // TODO: partnumber/vendor info
      const newTe: INewTestEvent = {
        date: d.toJSON(),
        location: cartonSerial,
        name: configVals.testName,
        result:  "P",
        serialNo: packageSerial,
        partNo: "",
        revision: "",
        vendor: "",
        output: "",
      }
      let ok = await sendScannedEvent(accessToken, newTe)
      .catch(err => {
        ok = false;
      })
      if (!ok && !errMsg) {
        setErrMsg("Error updating database");
        return;
      }
      const ix = scannedSerials.findIndex(s => s == packageSerial);
      if (ix >= 0) {
        scannedSerials.splice(ix, 1)
      }
      beep(50, 'sine', 610);
      setScannedSerials([packageSerial, ...scannedSerials]);
      setPackageSerial("");
      setErrMsg("")

    }
    sendPackage();
  }
  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }

  return <div className="txfwpage cartonpack-content">
    <h2>{configVals.title}</h2>
    <form>
      <label className="uk-label">Box Code</label>
      <br />
      <input className="uk-input uk-width-1-2"
        type="text"
        placeholder="Scan Carton Serial"
        value={cartonSerial}
        autoFocus={cartonSerial == ""}
        onChange={e => cartonChanged(e)}
      />
      <br />
      <br />
      <label className="uk-label">Package Serial Number</label>
      <br />
      <input className="uk-input uk-width-1-2"
        id="serialInput"
        type="text"
        placeholder="Scan package barcode"
        value={packageSerial}
        autoFocus={cartonSerial != ""}
        disabled={cartonSerial.length < 2}
        onChange={e => packageSerialScanned(e)}
      />
      <br />
      <div className="uk-text-danger">{errMsg}</div>
      <br />
      <button className="uk-button uk-button-primary "
        onClick={e => addPackage(e)}>Record</button>
    </form>
    <h2>Carton Count: {scannedSerials.length}</h2>
    <ul className="uk-list">
      {scannedSerials.map(t => <li>{t}</li>)}
    </ul>
  </div>
}
