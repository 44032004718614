
import { useAuth0 } from "@auth0/auth0-react";
import API from "@sesame/web-api";
import React, { useState } from "react";
import { Spinner } from "src/components/Spinner";
import Alert, { AlertTypes } from "src/components/AlertDisplay"
import { ILoadState, ITestEvent, UpgradeEvents } from "src/types";
import { Navigate } from "react-router-dom";
import dayjs from "dayjs";

export const TranscieversHistory = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [serial, setSerial] = useState("");
  const [txEvents, setTxEvents] = useState<ITestEvent[]>([]);
  const [errMsg, setErrMsg] = useState("");
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW)

  const getTransceiver = async (accessToken: string, serial: string): Promise<void> => {
    const res = await API.get(accessToken, `/api/v1/testEvent?serial=${serial}`)
    if (res.status != 200) {
      Alert(AlertTypes.ERROR, res.message)
      return;
    }

    const txs = res.data as ITestEvent[];

    //sort, with newest first
    setTxEvents(txs.sort((a, b) => a.date < b.date ? -1 : 1))
  }

  const getHistory = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    getAccessTokenSilently().then(accessToken => {
      const parts = serial.split(" ");
      if (parts.length == 0) {
       // not sure what's happening here, but we'll use what we have.
      return;
     }
      getTransceiver(accessToken, parts[0]).then( res => {
        setDisplayState(ILoadState.READY);
        return;
      })
      .catch( err => {
        setDisplayState(ILoadState.ERROR);
      } )
      ;
    })
    setDisplayState(ILoadState.LOADING);
  }

  const txScanned = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSerial(e.currentTarget.value);
    setErrMsg("");
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }
  if (isLoading || displayState == ILoadState.LOADING) {
    return <div><h2>Transceivers History</h2>
      <div><Spinner /></div>
    </div>
  }

  return <div>
    <h2>Transceiver History</h2>
    <form>
      <label className="uk-label">Transceiver Serial Number</label>
      <br />
      <input className="uk-input uk-width-1-2"
        id="serialInput"
        type="text"
        placeholder="Scan transceiver barcode"
        value={serial}
        autoFocus={true}
        onChange={e => txScanned(e)}
      />
      <br />
      <span className="uk-text-danger">{errMsg}</span>
      <br />
      <button className="uk-button uk-button-primary"
        onClick={e => getHistory(e)}>Get</button>
    </form>

    {displayState == ILoadState.NEW && <p>Eneter a transceiver serial number</p>}
    {displayState == ILoadState.ERROR && <p className="uk-text-danger">{errMsg}</p>}
    {displayState == ILoadState.READY && <table className="uk-table uk-table-small">
      <tr>
        <th>
          Result
        </th>
        <th>
          Date
        </th>
        <th>
          Test
        </th>
        <th>
          Location
        </th>
      </tr>

      {txEvents.map((t, i) => (
        <tr key={i}>
          <td>
            {t.result == 'P' && <span data-uk-icon="check" className="uk-text-success" />}
            {t.result == 'F' && <span data-uk-icon="close" className="uk-text-danger" />}
            {t.result == '-' && <span data-uk-icon="clock" className="uk-text-danger" />}
          </td>
          <td>
            {dayjs(t.date).format('DD/MM/YYYY HH:mm')}
          </td>
          <td>
            {UpgradeEvents[t.name]?.display ?? t.name}
          </td>
          <td>
            {t.location}
          </td>
        </tr>
      ))
      }
    </table>
}
  </div>
}
