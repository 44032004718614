import React, { useEffect, useState, useRef } from 'react';

export function HomePage() {

  return <React.Fragment>
    <div className="uk-container uk-padding-large">
      <h2>This site</h2>
      <p>Select Hosts to see current bench state</p>
      <p>Select transceivers to acccess transceiver database</p>
      <h2>Insight reporting</h2>
      <p>Live reporting is available on <a href="https://grafana.sesame.engineering">Grafana</a></p>
    </div>

    <div className="uk-background-primary uk-light uk-padding">
      Transceiver QA Management.  Copyright 2024 Flax Consulting
      <br />
    </div>
  </React.Fragment>

}
