
import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { ILoadState, INewTestEvent, ITestEvent, ITransceiver, ValidEventNames } from "src/types";
import { Navigate } from "react-router-dom";
import { getTransceiver, sendScannedEvent, workflowStates } from "./common";


export const FWUpgradeQA = () => {

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW)
  const [serial, setSerial] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [scannedTx, setScannedTx] = useState<string[]>([])

  const configVals = {
    title: 'Verify FW check',
    testName: workflowStates[3] as ValidEventNames,
  }

  const txScanned = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSerial(e.currentTarget.value);
    setErrMsg("");
  }

  const addScan = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const parts = serial.split(" ");
    const s = parts[0];
    var p = "";
    if (parts.length > 1) {
      p = parts[1];
    }

    const getTx = async () => {
      const accessToken = await getAccessTokenSilently();
      const txs = await getTransceiver(accessToken, workflowStates[2], s, 'P')

      if (txs.length == 0) {
        setErrMsg("Transceiver Firmware Not Recorded")
        return;
      }
      if (txs[0].result != 'P') {
        setErrMsg("Transceiver Firmware Not Upgraded")
        //TODO: get the PN and record the failure.  Can check if not upgraded or not tested.
        return;
      }

      const d = new Date()
      const newTe: INewTestEvent = {
        date: d.toJSON(),
        location: "Fw QA Scan station",
        name: configVals.testName,
        result: txs.length == 0 ? "F" : "P",
        serialNo: s,
        partNo: txs[0].partNo,
        revision: txs[0].revision,
        vendor: txs[0].vendor,
        output: "",
      }
      const ok = await sendScannedEvent(accessToken, newTe)
      if (!ok && !errMsg) {
        setErrMsg("Error updating database")
      }
      setScannedTx([s, ...scannedTx])
      setSerial("")
      document.getElementById('serialInput')?.focus();
    }
    getTx();

  }


  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }
  /*
  if (isLoading || displayState == ILoadState.NEW || displayState == ILoadState.LOADING) {
    <h2>{configVals[mode].title}</h2>
      <div><Spinner /></div>
    </div> 
  }
  */
  return <div>
    <h2>{configVals.title}</h2>
    <form>
      <label className="uk-label">Transceiver Serial Number</label>
      <br />
      <input className="uk-input uk-width-1-2"
        id="serialInput"
        type="text"
        placeholder="Scan transceiver barcode"
        value={serial}
        autoFocus={true}
        onChange={e => txScanned(e)}
      />
      <br />
      <span className="uk-text-danger">{errMsg}</span>
      <br />
      <button className="uk-button uk-button-primary"
        onClick={e => addScan(e)}>Record</button>
    </form>
    <h2>Scanned this session</h2>
    <ul className="uk-list">
      {scannedTx.map(t => <li>{t}</li>)}
    </ul>
  </div>
}
