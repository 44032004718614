import React, { useState, useEffect, useRef, useMemo } from "react";

import { Spinner } from "src/components/Spinner";
import API from "@sesame/web-api";
import { SwitchXcvrDetail, ILinkState, ILinkEvent, PortStatusType, IHostUnit, IFwCheckEvent, IServerEventType } from "src/types";
import { useAuth0 } from "@auth0/auth0-react";
import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source";
import AlertDisplay, { AlertTypes } from "src/components/AlertDisplay";
import Alert from "src/components/AlertDisplay";


const HostPortCounts = {
  "sn4700-prof": 60,
}


export type HostListT = Record<string, IHostUnit>

const initArray = Array(40).fill('unknown');
const EMPTYLINK: ILinkState = {
  interfaceName: "",
  remote: "",
  remoteHost: "",
  remotePort: "",
  state: "missing",
  MTU: "",
  speed: "",
  mode: ""
}
const EMPTYVERSION: IFwCheckEvent = {
  interfaceName: "",
  host: "",
  port: 0,
  serial: "",
  fwRev: "",
  result: "-",
}

export const SwitchFWStatus = () => {
  const [HostList, setHostList] = useState<HostListT>({})
  const [es, setEs] = useState<EventSource>();
  const [les, setLes] = useState<EventSource>();
  const [ready, setReady] = useState(false);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    getAccessTokenSilently().then(at => setAccessToken(at));
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchEventSource('/api/v1/events', {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        async onopen(res) {
          if (res.ok && res.status == 200) {
            console.log("Connection made")
          } else {
            console.log(`SSE Fetch Error: ${res.status} ${res.statusText}`)
          }
        },
        onmessage(msg) {
          if (msg.event == 'FatalError') { throw new Error(msg.data) }
          if (msg.event == 'xcvr-update') { handleXcvrUpdate(msg.data) }
          if (msg.event == 'fw-version-check') { handleFwVersion(msg.data) }
        },
        onclose() { },
        onerror(err) {
          console.log(err.message)
        },
      })
        .catch(err => {
          AlertDisplay(AlertTypes.ERROR, err.message);
        });
    }
    fetchData();
  }, [accessToken])

  const handleXcvrUpdate = (event: EventSourceMessage["event"]) => {

    const serverEvent = JSON.parse(event);
    let eventPort = serverEvent.port;
    let eventStatus = serverEvent.status;
    const eventHost = serverEvent.host;
    const eventInterfaceName = serverEvent.interfaceName

    if (eventStatus == "<nil>") {
      eventStatus = "on"
    }

    if (eventHost != "sn4700-prof") {
      return;
    }
    if (!(eventHost in HostList)) {
      const pc = (HostPortCounts as any)[eventHost] || 1;

      !ready && setReady(true)

      HostList[eventHost] = {
        hostname: eventHost,
        portCount: 0,
        ports: Array().fill(pc),
        model: '',
      }
    }
    if (eventPort == 0) {
      const existPort = HostList[eventHost].ports.findIndex(ppp => ppp && (ppp && (ppp.interfaceName == eventInterfaceName)))
      if (existPort == -1) {
        eventPort = HostList[eventHost].ports.length;
      } else {
        eventPort = existPort;
      }
    }
    const newPort: IServerEventType = {
      interfaceName: eventInterfaceName,
      status: eventStatus,
      port: eventPort,
      host: eventHost,
      xcvr: {
        vendor: serverEvent.xcvrVendor,
        partNo: serverEvent.xcvrPart,
        revision: serverEvent.xcvrRevision,
        serial: serverEvent.xcvrSerial,
        fwState: false,
      },
      version: HostList[eventHost].ports[eventPort]?.version ?? EMPTYVERSION,
      link: HostList[eventHost].ports[eventPort]?.link ?? EMPTYLINK,
    }
    if (eventPort == HostList[eventHost].ports.length) {
      HostList[eventHost].ports.push(newPort)
    } else if (eventPort < 0 || eventPort > 100) {
      return
    } else {
      HostList[eventHost].ports[eventPort] = newPort;
    }

    setHostList({ ...HostList })

  }


  const handleFwVersion = (event: EventSourceMessage["event"]) => {
    const fwEvent = JSON.parse(event) as IFwCheckEvent
    const eventHost = fwEvent.host;
    if (!HostList[eventHost]) {
      return;
    }
    const i = HostList[eventHost].ports.findIndex(p => p && p.interfaceName == fwEvent.interfaceName)
    if (i > -1) {
      const p = HostList[eventHost].ports[i];
      p.version = { ...fwEvent }
    setHostList({ ...HostList })
    }
  }

  const handleLinkpdate = (event: EventSourceMessage["event"]) => {
    const linkEvent = JSON.parse(event) as ILinkEvent
    const eventHost = linkEvent.host;

    //      console.log(':::: port: ' + eventPort)
    if (eventHost in HostList) {
      const i = HostList[eventHost].ports.findIndex(p => p && p.interfaceName == linkEvent.interfaceName)
      if (i > -1) {
        const p = HostList[eventHost].ports[i];

        p.link = {
          interfaceName: linkEvent.interfaceName,
          mode: linkEvent.mode,
          MTU: linkEvent.MTU,
          remote: linkEvent.remote,
          remoteHost: linkEvent.remoteHost,
          remotePort: linkEvent.remotePort,
          speed: linkEvent.speed,
          state: (function (s: string) {
            switch (s) {
              case "UP":
              case "DOWN":
                return s;
              case "DN":
                return "DOWN";
              default:
                return "missing"
            }
          }(linkEvent.linkState))
        }
        HostList[eventHost].ports[i] = { ...p }
        setHostList({ ...HostList })

      }
    }
  }


  return <div>
    <h1>Host Display</h1>
    {Object.keys(HostList).length} hosts
    {!ready && <h2>Waiting for data <Spinner /></h2>}
    {Object.keys(HostList).sort((a, b) => {
      if (a.startsWith("prof") && !b.startsWith("prof")) { return -1 }
      if (!a.startsWith("prof") && b.startsWith("prof")) { return 1 }
      if (a < b) { return -1 } return 1
    }
    ).map(h => (
      <div>
        {<DisplayHostUnit accessToken={accessToken} host={HostList[h]} />}
      </div>
    ))
    }
  </div>
}

interface DisplayHostProps {
  accessToken: string | undefined;
  host: IHostUnit;
}

const DisplayHostUnit = ({ accessToken, host }: DisplayHostProps) => {

  if (!accessToken) {
    return <div>Not available</div>
  }

  if (!host.ports.length) {
    return <div>No port data</div>
  }
  let lastp = host.ports[host.ports.length - 1].port;
  const knownPorts = Array(lastp).fill({})
  knownPorts.map((p, i) => {
    const ep = host.ports.find(pp => pp && pp.port == i + 1);
    knownPorts[i] = ep ?? { port: i }
  });
  const ports = [...knownPorts, ...host.ports.filter(p => p.port == 0)]
  ports.map((p, i) => {
    if (p.xcvr?.vendor == "<nil>") {
      ports[i].xcvr.vendor = ""
      ports[i].xcvr.partNo = ""
      ports[i].xcvr.revision = ""
    }
  })

  return <div>
    <div><h2>{host.hostname}</h2></div>
    {host.hostname.match(/^prof.*/) ? <DisplayHost ports={ports} /> : <DisplaySwitch ports={ports} />}
  </div>
}

interface IDisplayHostPortsProps {
  ports: IServerEventType[];
}

const DisplayHost = ({ ports }: IDisplayHostPortsProps) => {
  return <table className="uk-table uk-table-small switchTable hostTable">
    <tr>
      {
        ports.sort((a, b) => a.interfaceName < b.interfaceName ? -1 : 1).filter(p => p.interfaceName).map(p => {
          if (p.host) {
            return <Port port={p} />
          }
          return <td></td>
        })
      }
    </tr>
  </table>
}

const DisplaySwitch = ({ ports }: IDisplayHostPortsProps) => {
  return <table className="uk-table uk-table-small switchTable">
    <tr>
      {
        ports.filter((p, i) => i % 2 == 0).map(p => {
          if (p.host) {
            return <Port port={p} />
          }
          return <td></td>
        })
      }
    </tr>
    {ports.filter((p, i) => i % 2 == 0).map((p, i) => <td className="switchPortNumber">
      {i * 2 + 1}<span data-uk-icon="triangle-up" />
      <span data-uk-icon="triangle-down" />{i * 2 + 2}
    </td>)}
    <tr>
      {
        ports.filter((p, i) => i % 2 == 1).map(p => {
          if (p.host) {
            return <Port port={p} />
          }
          return <td></td>
        })
      }
    </tr>
  </table>
};


///////////////////////////////////////

interface PortProps {
  port: IServerEventType
}

const Port = ({ port }: PortProps) => {


  const sc = port.link.state.toLowerCase();

  let background = "portBgMissing";
  if (port.version.serial != "") {
    switch (port.version.result) {
      case "P": background = "portBgUpgraded"; break;
      case "F": background = "portBgNotUpgraded"; break;
    }
    if (port.xcvr.serial != port.version.serial) {
      background = "portBgError"
    }
  }
  if (port.status == "off") {
    background = "portBgEmpty"
  }


  return <td key={port.port} className={background}>
    <div className="tooltip">
      <span data-uk-icon="circle" className={`linkLight link-${sc}`}>●</span>
      {port.interfaceName}
      <br />
      {port.xcvr.vendor}
      <br />
      {port.xcvr.partNo}  {port.xcvr.revision ? ` : ${port.xcvr.revision} ` : ""}
      <br />
      {port.xcvr.serial == "<nil>" ? "" : `SN: ${port.xcvr.serial}`}
      <br />
      FW Ver: {port.version.fwRev}
    </div>
  </td>

}




