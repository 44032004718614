import React, { useEffect, useState, useRef } from 'react';
import LoginLink from 'src/components/LoginLink';

export function IndexPage() {

  return <React.Fragment>
    <div className="uk-container uk-padding-large">
      <h2>Login</h2>
      <p>You must login to access this site.</p>
      <LoginLink>
        <button className="uk-button uk-button-primary" >Login</button>
      </LoginLink>
    </div>
  </React.Fragment>

}
