import React, { useState, useEffect, useRef, useMemo } from "react";

import ModalDialog from "../components/ModalDialog"
import { Spinner } from "src/components/Spinner";
import API from "@sesame/web-api";
import axios from "axios";
import { SwitchXcvrDetail, ILinkState, ILinkEvent, PortStatusType, IHostUnit, IServerEventType } from "src/types";
import { useAuth0 } from "@auth0/auth0-react";
import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source";
import { unstable_useViewTransitionState } from "react-router-dom";
import AlertDisplay, { AlertTypes } from "src/components/AlertDisplay";
import Alert from "src/components/AlertDisplay";


const HostPortCounts = {
  "sn4700-prof": 60,
  "sn3400-prof": 48,
  "sn2201-prof": 48,
}

/*
interface IXcvr {
  vendor: string,
  partNo: string,
  revision: string,
  serial: string,
}

interface IServerEventType {
  host: string,
  interfaceName: string,
  port: number,
  xcvr: IXcvr,
  link: ILinkState,
}

interface IHostUnit {
  model: string,
  portCount: number,
  hostname: string,
  ports: IServerEventType[],
}
*/

export type HostListT = Record<string, IHostUnit>

const initArray = Array(40).fill('unknown');
const EMPTYLINK: ILinkState = {
  interfaceName: "",
  remote: "",
  remoteHost: "",
  remotePort: "",
  state: "missing",
  MTU: "",
  speed: "",
  mode: ""
}

export const HostPage = () => {
  const [HostList, setHostList] = useState<HostListT>({})
  const [es, setEs] = useState<EventSource>();
  const [les, setLes] = useState<EventSource>();
  const [ready, setReady] = useState(false);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    getAccessTokenSilently().then(at => setAccessToken(at));
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchData = async () => {
      const at = await getAccessTokenSilently();
      await fetchEventSource('/api/v1/events', {
        method: "GET",
        headers: {
          Authorization: `Bearer ${at}`
        },
        async onopen(res) {
          if (res.ok && res.status == 200) {
            console.log("Connection made")
          } else {
            console.log(`SSE Fetch Error: ${res.status} ${res.statusText}`)
          }
        },
        onmessage(msg) {
          if (msg.event == 'FatalError') { throw new Error(msg.data) }
          if (msg.event == 'xcvr-update') { handleXcvrUpdate(msg.data) }
          if (msg.event == 'link-event') { handleLinkpdate(msg.data) }
        },
        onclose() { },
        onerror(err) {
          console.log(err.message )
         },
      })
      .catch(err => {
        AlertDisplay(AlertTypes.ERROR, err.message);
      });
    }
    fetchData();
  }, [getAccessTokenSilently])

  const handleXcvrUpdate = (event: EventSourceMessage["event"]) => {

    //      console.log('Got event: ')
    const serverEvent = JSON.parse(event);
    let eventPort = serverEvent.port;
    const eventStatus = serverEvent.status;
    const eventHost = serverEvent.host;
    const eventInterfaceName = serverEvent.interfaceName

    //      console.log(':::: port: ' + eventPort + " "  + eventStatus)
    if (!(eventHost in HostList)) {
      const pc = (HostPortCounts as any)[eventHost] || 1;

      !ready && setReady(true)

      HostList[eventHost] = {
        hostname: eventHost,
        portCount: 0,
        ports: Array().fill(pc),
        model: '',
      }
    }
    if (eventPort == 0) {
      const existPort = HostList[eventHost].ports.findIndex(ppp => ppp && (ppp && (ppp.interfaceName == eventInterfaceName)))
      if (existPort == -1) {
        eventPort = HostList[eventHost].ports.length;
      } else {
        eventPort = existPort;
      }
    }
    const newPort: IServerEventType = {
      interfaceName: eventInterfaceName,
      status: eventStatus,
      port: eventPort,
      host: eventHost,
      xcvr: {
        vendor: serverEvent.xcvrVendor,
        partNo: serverEvent.xcvrPart,
        revision: serverEvent.xcvrRevision,
        serial: serverEvent.xcvrSerial,
        fwState: true,
      },
      link: HostList[eventHost].ports[eventPort]?.link ?? EMPTYLINK,
      version: {
        host: "",
        port: 0,
        interfaceName: "",
        result: "",
        serial: "",
        fwRev: ""
      },
    }
    if (eventPort == HostList[eventHost].ports.length) {
      HostList[eventHost].ports.push(newPort)
    } else if (eventPort < 0 || eventPort > 100) {
      return
    } else {
      HostList[eventHost].ports[eventPort] = newPort;
    }

    setHostList({ ...HostList })

  }

  const handleLinkpdate = (event: EventSourceMessage["event"]) => {
    const linkEvent = JSON.parse(event) as ILinkEvent
    const eventHost = linkEvent.host;

    //      console.log(':::: port: ' + eventPort)
    if (eventHost in HostList) {
      const i = HostList[eventHost].ports.findIndex(p => p && p.interfaceName == linkEvent.interfaceName)
      if (i > -1) {
        const p = HostList[eventHost].ports[i];

        p.link = {
          interfaceName: linkEvent.interfaceName,
          mode: linkEvent.mode,
          MTU: linkEvent.MTU,
          remote: linkEvent.remote,
          remoteHost: linkEvent.remoteHost,
          remotePort: linkEvent.remotePort,
          speed: linkEvent.speed,
          state: (function (s: string) {
            switch (s) {
              case "UP":
              case "DOWN":
                return s;
              case "DN":
                return "DOWN";
              default:
                return "missing"
            }
          }(linkEvent.linkState))
        }
        HostList[eventHost].ports[i] = { ...p }
        setHostList({ ...HostList })

      }
    }
  }


  return <div>
    <h1>Host Display</h1>
    {Object.keys(HostList).length} hosts
    {!ready && <h2>Waiting for data <Spinner /></h2>}
    {Object.keys(HostList).sort((a, b) => {
      if (a.startsWith("prof") && !b.startsWith("prof")) { return 1 }
      if (!a.startsWith("prof") && b.startsWith("prof")) { return 1 }
      if (a < b) { return -1 } return 1
    }
    ).map(h => (
      <div>
        {<DisplayHostUnit accessToken={accessToken} host={HostList[h]} />}
      </div>
    ))
    }
  </div>
}

interface DisplayHostProps {
  accessToken: string | undefined;
  host: IHostUnit;
}

const DisplayHostUnit = ({ accessToken, host }: DisplayHostProps) => {

  if (!accessToken) {
    return <div>Not available</div>
  }

  if (!host.ports.length) {
    return <div>No port data</div>
  }
  let lastp = host.ports[host.ports.length - 1].port;
  const knownPorts = Array(lastp).fill({})
  knownPorts.map((p, i) => {
    const ep = host.ports.find(pp => pp && pp.port == i + 1);
    knownPorts[i] = ep ?? { port: i }
  });
  const ports = [...knownPorts, ...host.ports.filter(p => p.port == 0)]
  ports.map((p, i) => {
    if (p.xcvr?.vendor == "<nil>") {
      ports[i].xcvr.vendor = ""
      ports[i].xcvr.partNo = ""
      ports[i].xcvr.revision = ""
    }
  })

  return <div>
    <div><h2>{host.hostname}
      <button
        className="uk-button uk-button-primary uk-button-small hButton"
        onClick={e => getPdf(accessToken, host)}>
        Get Labels
      </button>
      <button
        className="uk-button uk-button-primary uk-button-small hButton"
        onClick={e => getPdf(accessToken, host, true)}>
        Get Labels (with Cables)
      </button>
    </h2></div>
    {host.hostname.match(/^prof.*/) ? <DisplayHost ports={ports} /> : <DisplaySwitch ports={ports} />}
  </div>
}

interface IDisplayHostPortsProps {
  ports: IServerEventType[];
}

const DisplayHost = ({ ports }: IDisplayHostPortsProps) => {
  return <table className="uk-table uk-table-small switchTable hostTable">
    <tr>
      {
        ports.sort((a, b) => a.interfaceName < b.interfaceName ? -1 : 1).filter(p => p.interfaceName).map(p => {
          if (p.host) {
            return <Port port={p} />
          }
          return <td></td>
        })
      }
    </tr>
  </table>
}

const DisplaySwitch = ({ ports }: IDisplayHostPortsProps) => {
  return <table className="uk-table uk-table-small switchTable">
    <tr>
      {
        ports.filter((p, i) => i % 2 == 0).map(p => {
          if (p.host) {
            return <Port port={p} />
          }
          return <td></td>
        })
      }
    </tr>
    {ports.filter((p, i) => i % 2 == 0).map((p, i) => <td className="switchPortNumber">
      {i * 2 + 1}<span data-uk-icon="triangle-up" />
      <span data-uk-icon="triangle-down" />{i * 2 + 2}
    </td>)}
    <tr>
      {
        ports.filter((p, i) => i % 2 == 1).map(p => {
          if (p.host) {
            return <Port port={p} />
          }
          return <td></td>
        })
      }
    </tr>
  </table>
};


///////////////////////////////////////

interface PortProps {
  port: IServerEventType
}

const Port = ({ port }: PortProps) => {


  const sc = port.link.state.toLowerCase();

  return <td key={port.port} className="xcvrPresent">
    <div className="tooltip">
      <span data-uk-icon="circle" className={`linkLight link-${sc}`}>●</span>
      {port.interfaceName}
      <br />
      {port.xcvr.vendor}
      <br />
      {port.xcvr.partNo}  {port.xcvr.revision ? ` : ${port.xcvr.revision} ` : ""}
      <br />
      {port.xcvr.serial == "<nil>" ? "" : `SN: ${port.xcvr.serial}`}
      <span className="tooltiptext">
        <div className="uk-padding-small">
          Remote Host: {port.link.remoteHost}
          <br />
          Remote Port: {port.link.remotePort}
          <br />
          Speed: {port.link.speed}
          <br />
          Mode: {port.link.mode}
          <br />
          MTU: {port.link.MTU}
        </div>
      </span>
    </div>
  </td>

}


const getPdf = (accessToken: string, host: IHostUnit, double: boolean = false) => {

  const data: SwitchXcvrDetail = {
    host: host.hostname,
    ports: host.ports.filter(p => p.interfaceName > "" && p.xcvr.partNo > "").map(p => {
      return {
        host: host.hostname,
        interfaceName: p.interfaceName,
        port: p.port,
        status: p.link.state,
        xcvrVendor: p.xcvr.vendor,
        xcvrPart: p.xcvr.partNo,
        xcvrRevision: p.xcvr.revision,
        xcvrSerial: p.xcvr.serial,
      }
    })
  }
  if (double) {
    data.ports = data.ports.flatMap(p => [p, p])
  }


  API.post(accessToken, '/api/v1/pdf/labels', data, { responseType: "blob" })
    //axios.post('/pdf/labels', data, {responseType: "blob"})
    .then((res: any) => {
      const blob = new Blob([res.data], { type: "application/pdf" })
      return blob;
    })
    .then(blob => {
      var url = window.URL.createObjectURL(blob);
      window.open(url)
      var a = document.createElement('a');
      /*
      a.href = url;
      a.download = "labels.pdf";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();  //afterwards we remove the element again         
      */
    })
    .catch(err => {
      console.log(err.toString())
      return
    })
}



