import API from "@sesame/web-api";
import Alert, { AlertTypes } from "src/components/AlertDisplay";
import { INewTestEvent, ITestEvent } from "src/types";

export const getTransceiver = async (accessToken: string, testName: string, serial: string, result = "any"): Promise<ITestEvent[]> => {
  var resultParam = ""
  if (result != "any") {
    resultParam=`&result=${result}`
  }
  
  const res = await API.get(accessToken, `/api/v1/testEvent?serial=${serial}&name=${testName}${resultParam}`)
  if (res.status != 200) {
    Alert(AlertTypes.ERROR, res.message)
    return [];
  }

  const txs = res.data as ITestEvent[];

  //sort, with newest first
  return txs.sort((a, b) => a.date > b.date ? -1 : 1)

}

export const sendScannedEvent = async (accessToken: string, te: INewTestEvent) => {
  const res = await API.post(accessToken, "/api/v1/testEvent", te)
  .catch(err => {
    Alert(AlertTypes.ERROR, err.message);
    throw err;
  })

  return res.status == 200;
}


export const workflowStates = [
  "fwu:checkin",
  "fwu:upgrade",
  "fwu:upgradeversioncheck",
  "fwu:upgradeqascan",
  "fwu:packout",
  "inv:cartonpack",
  "fwu:error"
]